export const FETCH_SESSION_RESERVATIONS_LIST_START =
  'FETCH_SESSION_RESERVATIONS_LIST_START';
export const FETCH_SESSION_RESERVATIONS_LIST_SUCCESS =
  'FETCH_SESSION_RESERVATIONS_LIST_SUCCESS';
export const FETCH_SESSION_RESERVATIONS_LIST_FAIL =
  'FETCH_SESSION_RESERVATIONS_LIST_FAIL';

export const FETCH_USER_RESERVATIONS_LIST_START =
  'FETCH_USER_RESERVATIONS_LIST_START';
export const FETCH_USER_RESERVATIONS_LIST_SUCCESS =
  'FETCH_USER_RESERVATIONS_LIST_SUCCESS';
export const FETCH_USER_RESERVATIONS_LIST_FAIL =
  'FETCH_USER_RESERVATIONS_LIST_FAIL';

export const CREATE_RESERVATION_START = 'CREATE_RESERVATION_START';
export const CREATE_RESERVATION_FAIL = 'CREATE_RESERVATION_FAIL';
export const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS';
export const RESET_CREATE_RESERVATION_STORE = 'RESET_CREATE_RESERVATION_STORE';

export const FETCH_USER_RESERVATIONS_HISTORY_START =
  'FETCH_USER_RESERVATION_HISTORY_START';
export const FETCH_USER_RESERVATIONS_HISTORY_SUCCESS =
  'FETCH_USER_RESERVATION_HISTORY_SUCCESS';
export const FETCH_USER_RESERVATIONS_HISTORY_FAIL =
  'FETCH_USER_RESERVATION_HISTORY_FAIL';

export const DELETE_RESERVATION_START = 'DELETE_RESERVATION_START';
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS';
export const DELETE_RESERVATION_FAIL = 'DELETE_RESERVATION_FAIL';

export const CANCEL_USER_RESERVATION_START = 'CANCEL_USER_RESERVATION_START';
export const CANCEL_USER_RESERVATION_SUCCESS =
  'CANCEL_USER_RESERVATION_SUCCESS';
export const CANCEL_USER_RESERVATION_FAIL = 'CANCEL_USER_RESERVATION_FAIL';

export const RESET_RESERVATION_STORE = 'RESET_RESERVATION_STORE';

export const LOGOUT = 'LOGOUT';

export type ReservationActionTypes =
  | typeof FETCH_SESSION_RESERVATIONS_LIST_START
  | typeof FETCH_SESSION_RESERVATIONS_LIST_SUCCESS
  | typeof FETCH_SESSION_RESERVATIONS_LIST_FAIL
  | typeof FETCH_USER_RESERVATIONS_LIST_START
  | typeof FETCH_USER_RESERVATIONS_LIST_SUCCESS
  | typeof FETCH_USER_RESERVATIONS_LIST_FAIL
  | typeof CREATE_RESERVATION_START
  | typeof CREATE_RESERVATION_SUCCESS
  | typeof CREATE_RESERVATION_FAIL
  | typeof RESET_CREATE_RESERVATION_STORE
  | typeof DELETE_RESERVATION_START
  | typeof DELETE_RESERVATION_SUCCESS
  | typeof DELETE_RESERVATION_FAIL
  | typeof CANCEL_USER_RESERVATION_START
  | typeof CANCEL_USER_RESERVATION_SUCCESS
  | typeof CANCEL_USER_RESERVATION_FAIL
  | typeof FETCH_USER_RESERVATIONS_HISTORY_START
  | typeof FETCH_USER_RESERVATIONS_HISTORY_SUCCESS
  | typeof FETCH_USER_RESERVATIONS_HISTORY_FAIL
  | typeof RESET_RESERVATION_STORE
  | typeof LOGOUT;

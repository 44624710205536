export const FETCH_PROMO_CODES_START = 'FETCH_PROMO_CODES_START';
export const FETCH_PROMO_CODES_SUCCESS = 'FETCH_PROMO_CODES_SUCCESS';
export const FETCH_PROMO_CODES_FAIL = 'FETCH_PROMO_CODES_FAIL';

export const FETCH_ALL_PROMO_CODES_START = 'FETCH_ALL_PROMO_CODES_START';
export const FETCH_ALL_PROMO_CODES_SUCCESS = 'FETCH_ALL_PROMO_CODES_SUCCESS';
export const FETCH_ALL_PROMO_CODES_FAIL = 'FETCH_ALL_PROMO_CODES_FAIL';

export const FETCH_AVAILABLE_PROMO_CODES_START =
  'FETCH_AVAILABLE_PROMO_CODES_START';
export const FETCH_AVAILABLE_PROMO_CODES_SUCCESS =
  'FETCH_AVAILABLE_PROMO_CODES_SUCCESS';
export const FETCH_AVAILABLE_PROMO_CODES_FAIL =
  'FETCH_AVAILABLE_PROMO_CODES_FAIL';

export const CREATE_PROMO_CODE_START = 'CREATE_PROMO_CODE_START';
export const CREATE_PROMO_CODE_SUCCESS = 'CREATE_PROMO_CODE_SUCCESS';
export const CREATE_PROMO_CODE_FAIL = 'CREATE_PROMO_CODE_FAIL';

export const UPDATE_PROMO_CODE_START = 'UPDATE_PROMO_CODE_START';
export const UPDATE_PROMO_CODE_SUCCESS = 'UPDATE_PROMO_CODE_SUCCESS';
export const UPDATE_PROMO_CODE_FAIL = 'UPDATE_PROMO_CODE_FAIL';

export const DELETE_PROMO_CODE_START = 'DELETE_PROMO_CODE_START';
export const DELETE_PROMO_CODE_SUCCESS = 'DELETE_PROMO_CODE_SUCCESS';
export const DELETE_PROMO_CODE_FAIL = 'DELETE_PROMO_CODE_FAIL';

export const RESET_PROMO_CODE_STORE = 'RESET_PROMO_CODE_STORE';
export const RESET_CREATED_PROMO_CODE = 'RESET_CREATED_PROMO_CODE';

export const LOGOUT = 'LOGOUT';

export type PromoCodeActionTypes =
  | typeof FETCH_PROMO_CODES_START
  | typeof FETCH_PROMO_CODES_SUCCESS
  | typeof FETCH_PROMO_CODES_FAIL
  | typeof FETCH_ALL_PROMO_CODES_START
  | typeof FETCH_ALL_PROMO_CODES_SUCCESS
  | typeof FETCH_ALL_PROMO_CODES_FAIL
  | typeof FETCH_AVAILABLE_PROMO_CODES_START
  | typeof FETCH_AVAILABLE_PROMO_CODES_SUCCESS
  | typeof FETCH_AVAILABLE_PROMO_CODES_FAIL
  | typeof CREATE_PROMO_CODE_START
  | typeof CREATE_PROMO_CODE_SUCCESS
  | typeof CREATE_PROMO_CODE_FAIL
  | typeof UPDATE_PROMO_CODE_START
  | typeof UPDATE_PROMO_CODE_SUCCESS
  | typeof UPDATE_PROMO_CODE_FAIL
  | typeof DELETE_PROMO_CODE_START
  | typeof DELETE_PROMO_CODE_SUCCESS
  | typeof DELETE_PROMO_CODE_FAIL
  | typeof RESET_PROMO_CODE_STORE
  | typeof RESET_CREATED_PROMO_CODE
  | typeof LOGOUT;

import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { FbPixelActionTypes } from './actionTypes';

export type FbPixelStateType = {
  sendFbPixelEventLoading: boolean;
  sendFbPixelEventSuccess: boolean;
  sendFbPixelEventError: HttpError;
};

export type FbPixelActionType = FbPixelStateType & {
  type: FbPixelActionTypes;
  value: string;
};

export const initialState: FbPixelStateType = {
  sendFbPixelEventLoading: false,
  sendFbPixelEventError: null,
  sendFbPixelEventSuccess: false,
};

const sendFbPixelEventStart = (state: FbPixelStateType): FbPixelStateType => ({
  ...state,
  sendFbPixelEventLoading: true,
  sendFbPixelEventSuccess: false,
});

const sendFbPixelEventSuccess = (
  state: FbPixelStateType,
  action: FbPixelActionType,
): FbPixelStateType => ({
  ...state,
  sendFbPixelEventSuccess: true,
  sendFbPixelEventLoading: false,
  sendFbPixelEventError: null,
});

const sendFbPixelEventFail = (
  state: FbPixelStateType,
  action: FbPixelActionType,
): FbPixelStateType => ({
  ...state,
  sendFbPixelEventError: action.sendFbPixelEventError,
  sendFbPixelEventLoading: false,
  sendFbPixelEventSuccess: false,
});

const resetFbPixelStore = (): FbPixelStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: FbPixelActionType) => {
  switch (action.type) {
    case actionTypes.SEND_FB_PIXEL_EVENT_START:
      return sendFbPixelEventStart(state);
    case actionTypes.SEND_FB_PIXEL_EVENT_SUCCESS:
      return sendFbPixelEventSuccess(state, action);
    case actionTypes.SEND_FB_PIXEL_EVENT_FAIL:
      return sendFbPixelEventFail(state, action);
    case actionTypes.RESET_FB_PIXEL_EVENT_STORE:
      return resetFbPixelStore();
    case actionTypes.LOGOUT:
      return resetFbPixelStore();
    default:
      return state;
  }
};

export default reducer;

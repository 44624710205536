export const SEND_FB_PIXEL_EVENT_START = 'SEND_FB_PIXEL_EVENT_START';
export const SEND_FB_PIXEL_EVENT_SUCCESS = 'SEND_FB_PIXEL_EVENT_SUCCESS';
export const SEND_FB_PIXEL_EVENT_FAIL = 'SEND_FB_PIXEL_EVENT_SUCCESS';

export const RESET_FB_PIXEL_EVENT_STORE = 'RESET_FB_PIXEL_EVENT_STORE';

export const LOGOUT = 'LOGOUT';

export type FbPixelActionTypes =
  | typeof SEND_FB_PIXEL_EVENT_START
  | typeof SEND_FB_PIXEL_EVENT_SUCCESS
  | typeof SEND_FB_PIXEL_EVENT_FAIL
  | typeof RESET_FB_PIXEL_EVENT_STORE
  | typeof LOGOUT;

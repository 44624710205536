import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { PromoCode } from '../../domain/PromoCode';
import { ListResults } from '../../common/List/List';
import { PromoCodeActionTypes } from './actionTypes';

export type PromoCodeStateType = {
  promoCodesList: ListResults<PromoCode> | null;
  promoCodesLoading: boolean;
  promoCodesError: HttpError;
  promoCodeCreateLoading: boolean;
  promoCodeCreateError: HttpError;
  promoCodeCreateSuccess: boolean;
  promoCodeUpdateLoading: boolean;
  promoCodeUpdateError: HttpError;
  promoCodeUpdateSuccess: boolean;
  promoCodeDeleteLoading: boolean;
  promoCodeDeleteError: HttpError;
  promoCodeDeleteSuccess: boolean;
  promoCodesListUpdateNeeded: boolean;
  createdPromoCode: PromoCode | null;
  allPromoCodes: PromoCode[] | null;
  allPromoCodesLoading: boolean;
  allPromoCodesError: HttpError;
  availablePromoCodes: PromoCode[];
  availablePromoCodesLoading: boolean;
  availablePromoCodesError: HttpError;
  validPromoCode: PromoCode | null;
};

export type PromoCodeActionType = PromoCodeStateType & {
  type: PromoCodeActionTypes;
};

export const initialState: PromoCodeStateType = {
  promoCodeCreateError: null,
  promoCodeCreateLoading: false,
  promoCodeCreateSuccess: false,
  promoCodeDeleteError: null,
  promoCodeDeleteLoading: false,
  promoCodeDeleteSuccess: false,
  promoCodeUpdateError: null,
  promoCodeUpdateLoading: false,
  promoCodeUpdateSuccess: false,
  promoCodesError: null,
  promoCodesList: null,
  promoCodesLoading: true,
  promoCodesListUpdateNeeded: false,
  createdPromoCode: null,
  allPromoCodesError: null,
  allPromoCodes: null,
  allPromoCodesLoading: false,
  availablePromoCodesError: null,
  availablePromoCodes: [],
  availablePromoCodesLoading: false,
  validPromoCode: null,
};

const fetchPromoCodesStart = (
  state: PromoCodeStateType,
): PromoCodeStateType => ({
  ...state,
  promoCodesLoading: true,
  promoCodeUpdateSuccess: false,
  promoCodeCreateSuccess: false,
  promoCodeDeleteSuccess: false,
});

const fetchPromoCodesSuccess = (
  state: PromoCodeStateType,
  action: PromoCodeActionType,
): PromoCodeStateType => ({
  ...state,
  promoCodesList: action.promoCodesList,
  promoCodesLoading: false,
  promoCodesError: null,
  promoCodesListUpdateNeeded: false,
});

const fetchPromoCodesFail = (
  state: PromoCodeStateType,
  action: PromoCodeActionType,
): PromoCodeStateType => ({
  ...state,
  promoCodesError: action.promoCodesError,
  promoCodesLoading: false,
});

const fetchAllPromoCodesStart = (
  state: PromoCodeStateType,
): PromoCodeStateType => ({
  ...state,
  allPromoCodesLoading: true,
});

const fetchAllPromoCodesSuccess = (
  state: PromoCodeStateType,
  action: PromoCodeActionType,
): PromoCodeStateType => ({
  ...state,
  allPromoCodes: action.allPromoCodes,
  allPromoCodesLoading: false,
  allPromoCodesError: null,
});

const fetchAllPromoCodesFail = (
  state: PromoCodeStateType,
  action: PromoCodeActionType,
): PromoCodeStateType => ({
  ...state,
  allPromoCodesError: action.allPromoCodesError,
  allPromoCodesLoading: false,
});

const createPromoCodeStart = (
  state: PromoCodeStateType,
): PromoCodeStateType => ({
  ...state,
  promoCodeCreateLoading: true,
});

const createPromoCodeSuccess = (
  state: PromoCodeStateType,
  action: PromoCodeActionType,
): PromoCodeStateType => ({
  ...state,
  promoCodeCreateLoading: false,
  promoCodeCreateError: null,
  promoCodeCreateSuccess: true,
  promoCodesListUpdateNeeded: true,
  createdPromoCode: action.createdPromoCode,
});

const createPromoCodeFail = (
  state: PromoCodeStateType,
  action: PromoCodeActionType,
): PromoCodeStateType => ({
  ...state,
  promoCodeCreateLoading: false,
  promoCodeCreateError: action.promoCodeCreateError,
});

const updatePromoCodeStart = (
  state: PromoCodeStateType,
): PromoCodeStateType => ({
  ...state,
  promoCodeUpdateLoading: true,
});

const updatePromoCodeSuccess = (
  state: PromoCodeStateType,
): PromoCodeStateType => ({
  ...state,
  promoCodeUpdateLoading: false,
  promoCodeUpdateError: null,
  promoCodeUpdateSuccess: true,
  promoCodesListUpdateNeeded: true,
});

const updatePromoCodeFail = (
  state: PromoCodeStateType,
  action: PromoCodeActionType,
): PromoCodeStateType => ({
  ...state,
  promoCodeUpdateLoading: false,
  promoCodeUpdateError: action.promoCodeUpdateError,
});

const deletePromoCodeStart = (
  state: PromoCodeStateType,
): PromoCodeStateType => ({
  ...state,
  promoCodeDeleteLoading: true,
});

const deletePromoCodeSuccess = (
  state: PromoCodeStateType,
): PromoCodeStateType => ({
  ...state,
  promoCodeDeleteLoading: false,
  promoCodeDeleteError: null,
  promoCodeDeleteSuccess: true,
  promoCodesListUpdateNeeded: true,
});

const deletePromoCodeFail = (
  state: PromoCodeStateType,
  action: PromoCodeActionType,
): PromoCodeStateType => ({
  ...state,
  promoCodeDeleteLoading: false,
  promoCodeDeleteError: action.promoCodeDeleteError,
});

const resetPromoCodeStore = (
  state: PromoCodeStateType,
): PromoCodeStateType => ({
  ...initialState,
});

const completelyResetPromoCodeStore = (): PromoCodeStateType => ({
  ...initialState,
});

const resetCreatePromoCodeStore = (
  state: PromoCodeStateType,
): PromoCodeStateType => ({
  ...state,
  createdPromoCode: null,
});

const reducer = (state = initialState, action: PromoCodeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PROMO_CODES_START:
      return fetchPromoCodesStart(state);
    case actionTypes.FETCH_PROMO_CODES_SUCCESS:
      return fetchPromoCodesSuccess(state, action);
    case actionTypes.FETCH_PROMO_CODES_FAIL:
      return fetchPromoCodesFail(state, action);
    case actionTypes.FETCH_ALL_PROMO_CODES_START:
      return fetchAllPromoCodesStart(state);
    case actionTypes.FETCH_ALL_PROMO_CODES_SUCCESS:
      return fetchAllPromoCodesSuccess(state, action);
    case actionTypes.FETCH_ALL_PROMO_CODES_FAIL:
      return fetchAllPromoCodesFail(state, action);
    case actionTypes.CREATE_PROMO_CODE_START:
      return createPromoCodeStart(state);
    case actionTypes.CREATE_PROMO_CODE_SUCCESS:
      return createPromoCodeSuccess(state, action);
    case actionTypes.CREATE_PROMO_CODE_FAIL:
      return createPromoCodeFail(state, action);
    case actionTypes.UPDATE_PROMO_CODE_START:
      return updatePromoCodeStart(state);
    case actionTypes.UPDATE_PROMO_CODE_SUCCESS:
      return updatePromoCodeSuccess(state);
    case actionTypes.UPDATE_PROMO_CODE_FAIL:
      return updatePromoCodeFail(state, action);
    case actionTypes.DELETE_PROMO_CODE_START:
      return deletePromoCodeStart(state);
    case actionTypes.DELETE_PROMO_CODE_SUCCESS:
      return deletePromoCodeSuccess(state);
    case actionTypes.DELETE_PROMO_CODE_FAIL:
      return deletePromoCodeFail(state, action);
    case actionTypes.RESET_PROMO_CODE_STORE:
      return resetPromoCodeStore(state);
    case actionTypes.RESET_CREATED_PROMO_CODE:
      return resetCreatePromoCodeStore(state);
    case actionTypes.LOGOUT:
      return completelyResetPromoCodeStore();
    default:
      return state;
  }
};

export default reducer;

import { HttpError } from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';

export const sendFbPixelEventStart = () => ({
  type: actionTypes.SEND_FB_PIXEL_EVENT_START,
});

export const sendFbPixelEventSuccess = () => ({
  type: actionTypes.SEND_FB_PIXEL_EVENT_SUCCESS,
});

export const sendFbPixelEventFail = (sendFbPixelEventError: HttpError) => ({
  type: actionTypes.SEND_FB_PIXEL_EVENT_FAIL,
  sendFbPixelEventError,
});

export const resetFbPixelStore = () => ({
  type: actionTypes.RESET_FB_PIXEL_EVENT_STORE,
});

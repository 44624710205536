import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import enquiryReducer, { EnquiryStateType } from '../../store/enquiry/reducer';
import sessionTypeReducer, {
  SessionTypeStateType,
} from '../../store/session-type/reducer';
import subscriptionTypeReducer, {
  SubscriptionTypeStateType,
} from '../../store/subscription-type/reducer';

import couponReducer, { CouponStateType } from '../../store/coupon/reducer';
import sessionReducer, { SessionStateType } from '../../store/session/reducer';

import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';
import reservationReducer, {
  ReservationStateType,
} from '../../store/reservation/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import emailReducer, { EmailStateType } from '../../store/email/reducer';
import promoCodeReducer, {
  PromoCodeStateType,
} from '../../store/promo-code/reducer';
import statisticsReducer, {
  StatisticsStateType,
} from '../../store/statistics/reducer';
import fbPixelReducer, { FbPixelStateType } from '../../store/fb-pixel/reducer';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  sessionType: SessionTypeStateType;
  subscriptionType: SubscriptionTypeStateType;
  coupon: CouponStateType;
  session: SessionStateType;
  enquiry: EnquiryStateType;
  payment: PaymentStateType;
  reservation: ReservationStateType;
  email: EmailStateType;
  promoCode: PromoCodeStateType;
  statistics: StatisticsStateType;
  fbPixel: FbPixelStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  sessionType: sessionTypeReducer,
  subscriptionType: subscriptionTypeReducer,
  coupon: couponReducer,
  session: sessionReducer,
  enquiry: enquiryReducer,
  payment: paymentReducer,
  reservation: reservationReducer,
  email: emailReducer,
  promoCode: promoCodeReducer,
  statistics: statisticsReducer,
  fbPixel: fbPixelReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;

import { Dispatch } from 'redux';
import axios from '../../config/Axios/axios-instance';
import {
  sendFbPixelEventFail,
  sendFbPixelEventStart,
  sendFbPixelEventSuccess,
} from './action';

const API_URL = '/fb-pixel';

export enum FbPixelEventName {
  // eslint-disable-next-line no-unused-vars
  VIEW_CONTENT = 'ViewContent',
  // eslint-disable-next-line no-unused-vars
  CONTACT = 'Contact',
  // eslint-disable-next-line no-unused-vars
  INITIATE_CHECKOUT = 'InitiateCheckout',
}

export type SendFbPixelEvent = {
  eventName: FbPixelEventName;
};

export const sendFbPixelEvent =
  (inputs: SendFbPixelEvent) => (dispatch: Dispatch) => {
    dispatch(sendFbPixelEventStart());

    return axios
      .post(API_URL, inputs)
      .then(() => {
        dispatch(sendFbPixelEventSuccess());
      })
      .catch((err) => {
        dispatch(sendFbPixelEventFail(err?.response?.data?.error));
      });
  };

import * as actionTypes from './actionTypes';
import { StatisticsActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Statistics, UserStatistics } from '../../domain/Statistics';

export type StatisticsStateType = {
  statistics: Statistics | null;
  statisticsLoading: boolean;
  statisticsError: HttpError;
  userStatistics: UserStatistics | null;
  userStatisticsLoading: boolean;
  userStatisticsError: HttpError;
};

export type StatisticsActionType = StatisticsStateType & {
  type: StatisticsActionTypes;
  value: string;
};

export const initialState: StatisticsStateType = {
  statistics: null,
  statisticsLoading: false,
  statisticsError: null,
  userStatistics: null,
  userStatisticsLoading: false,
  userStatisticsError: null,
};

const fetchStatisticsStart = (
  state: StatisticsStateType,
): StatisticsStateType => ({
  ...state,
  statisticsLoading: true,
});

const fetchStatisticsSuccess = (
  state: StatisticsStateType,
  action: StatisticsActionType,
): StatisticsStateType => ({
  ...state,
  statistics: action.statistics,
  statisticsLoading: false,
  statisticsError: null,
});

const fetchStatisticsFail = (
  state: StatisticsStateType,
  action: StatisticsActionType,
): StatisticsStateType => ({
  ...state,
  statisticsError: action.statisticsError,
  statisticsLoading: false,
});

const fetchUserStatisticsStart = (
  state: StatisticsStateType,
): StatisticsStateType => ({
  ...state,
  userStatisticsLoading: true,
});

const fetchUserStatisticsSuccess = (
  state: StatisticsStateType,
  action: StatisticsActionType,
): StatisticsStateType => ({
  ...state,
  userStatistics: action.userStatistics,
  userStatisticsLoading: false,
  userStatisticsError: null,
});

const fetchUserStatisticsFail = (
  state: StatisticsStateType,
  action: StatisticsActionType,
): StatisticsStateType => ({
  ...state,
  userStatisticsError: action.userStatisticsError,
  userStatisticsLoading: false,
});

const resetStatisticsStore = (): StatisticsStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: StatisticsActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_STATISTICS_START:
      return fetchStatisticsStart(state);
    case actionTypes.FETCH_STATISTICS_SUCCESS:
      return fetchStatisticsSuccess(state, action);
    case actionTypes.FETCH_STATISTICS_FAIL:
      return fetchStatisticsFail(state, action);
    case actionTypes.FETCH_USER_STATISTICS_START:
      return fetchUserStatisticsStart(state);
    case actionTypes.FETCH_USER_STATISTICS_SUCCESS:
      return fetchUserStatisticsSuccess(state, action);
    case actionTypes.FETCH_USER_STATISTICS_FAIL:
      return fetchUserStatisticsFail(state, action);
    case actionTypes.RESET_STATISTICS_STORE:
      return resetStatisticsStore();
    case actionTypes.LOGOUT:
      return resetStatisticsStore();
    default:
      return state;
  }
};

export default reducer;
